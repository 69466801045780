import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-page-2',
  templateUrl: './page-2.component.html'
})
export class Page2Component implements OnInit {

  constructor(
    private appService: AppService,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
    this.appService.pageTitle = 'Users';
  }

  users = [];
  url = environment.baseUrl;

  loading = false;

  config: any;
  collection = { count: 0, data: [] };


  ngOnInit() {
    this.loading = true;
    this.userService.GetAllUsers()
      .subscribe((resp: any) => {
        this.users = resp;
        console.log(this.users)
        this.loading = false;
      });

    this.config = {
      itemsPerPage: 50,
      currentPage: 1,
      totalItems: this.collection.count
    };
    this.collection.count = this.users.length

  }


  getOnline() {
    let array = [];
    this.users.forEach(element => {
      if (element.isOnline) {
        array.push(element)
      }
    });

    return array.length
  }

  deleteData = {
    id: 0,
    firstName: '',
    lastName: '',
  }

  updateId = 0;
  SelectedRoleId = '';

  selectChange(ev) {
    this.SelectedRoleId = ev.target.value;
  }

  updateRoleClick(targetModal, item) {
    this.updateId = item.id;
    this.SelectedRoleId = item.roleId;
    this.modalService.open(targetModal, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
  }

  updateRole() {

    let data = {
      roleId: JSON.parse(this.SelectedRoleId)
    }


    this.userService.UpdateRoleId(this.updateId, data)
      .subscribe((resp: any) => {
        if (resp.message) {
          this.modalService.dismissAll();
          this.ngOnInit()

        }

      })
  }

  deleteClick(targetModal, item) {
    this.deleteData = item;
    this.modalService.open(targetModal, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
  }

  deleteUser(id) {
    this.userService.DeleteUser(id)
      .subscribe((resp: any) => {
        this.modalService.dismissAll();
        this.ngOnInit();
      })
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }


  isApprovedChange(event, id) {
    let data = {
      isApproved: event
    }
    this.userService.Approve_User(id, data)
      .subscribe((resp: any) => {
      })

  }

  isDisableChange(event, id) {
    let data = {
      isAccountDisabled: event
    }

    this.userService.Disable_User_Account(id, data)
      .subscribe((resp: any) => {
      })
  }

  isAdminChange(event, id) {
    let data = {
      isAdmin: event
    }

    this.userService.Is_Admin(id, data)
      .subscribe((resp: any) => { console.log(resp) })
  }
}
