import { InterceptorTokenService } from './interceptor-token.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { LoginComponent } from './login/login.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// *******************************************************************************
//
import { NgxPaginationModule } from 'ngx-pagination';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PoiTypesComponent } from './poi-types/poi-types.component';
import { PoiComponent } from './poi/poi.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierTypesComponent } from './supplier-types/supplier-types.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CreateSupplierComponent } from './create-supplier/create-supplier.component';
import { InvitecodeComponent } from './invitecode/invitecode.component';
import { PoiMapComponent } from './poi-map/poi-map.component';

@NgModule({
  declarations: [
    AppComponent,

    // Pages
    HomeComponent,
    Page2Component,
    LoginComponent,
    PoiTypesComponent,
    PoiComponent,
    SuppliersComponent,
    SupplierTypesComponent,
    CreateSupplierComponent,
    InvitecodeComponent,
    PoiMapComponent
  ],

  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    UiSwitchModule,
    HttpClientModule,
    GooglePlaceModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCmzxRulX4wvyKqThk4zZU9zKGNIQaX8CQ',
      libraries: ['places']
    }),
    // App
    AppRoutingModule,
    LayoutModule
  ],

  providers: [
    Title,
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorTokenService,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
