import { Component, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
declare var google: any;
import {
  GoogleMapsAPIWrapper,
  AgmMap,
  MapsAPILoader,
  LatLngBounds,
  LatLngBoundsLiteral
} from "@agm/core";
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {
  @ViewChild(AgmMap) agmMap: AgmMap;

  constructor(
    private appService: AppService,
    private userService: UserService) {
    this.appService.pageTitle = 'Home';
  }

  zoom = 3;
  url = environment.baseUrl;
  urlIconsUsers = [];
  getusersMarkers(i) {
    return this.urlIconsUsers[i];
  }
  users = []
  totalUsers = 0

  ngOnInit() {
    this.userService.GetAllUsers()
      .subscribe((resp: any) => {
        this.totalUsers = resp.length;
        this.users = resp;
        resp.forEach(item => {
          if (item.imageUrl) {
            this.urlIconsUsers.push({
              url: this.url + "/" + item.imageUrl,
              scaledSize: {
                width: 40,
                height: 40
              }
            });
          } else {
            this.urlIconsUsers.push({
              url: "assets/male.jpeg",
              scaledSize: {
                width: 40,
                height: 40
              }
            });
          }
        })
      });
  }

}
