import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PoiService } from '../services/poi.service';

@Component({
  selector: 'app-poi-types',
  templateUrl: './poi-types.component.html',
  styleUrls: ['./poi-types.component.scss']
})
export class PoiTypesComponent implements OnInit {


  constructor(
    private modalService: NgbModal,
    private poiService: PoiService,
  ) { }

  poiTypes = [];

  ngOnInit() {
    this.poiService.GetAll_PoiTypes()
      .subscribe((resp: any) => {
        this.poiTypes = resp;
      })
  }

  createData = {
    type: '',
    color: ''
  }

  editData = {
    type: '',
    color: ''
  }

  deleteData = {
    id: 0,
    type: ""
  }
  
  editId = 0;

  openModal(targetModal, item) {
    if (item) {
      this.editId = item.id;
      this.editData.type = item.type;
      this.editData.color = item.color;
    }

    this.modalService.open(targetModal, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });

  }

  createPoiType() {
    if (this.createData.type) {
      this.poiService.Create_PoiTypes(this.createData)
        .subscribe((resp: any) => {

          this.modalService.dismissAll()

          this.createData.type = '';
          this.createData.color = '';
          this.ngOnInit()
        })
    }
  }

  editClick() {

    if (this.editData.type) {
      this.poiService.Update_PoiTypes(this.editId, this.editData)
        .subscribe((resp: any) => {
          this.modalService.dismissAll()
          this.ngOnInit()
        })
    }
  }
  deleteClick(targetModal, item) {
    this.deleteData.type = item.type;
    this.deleteData.id = item.id;

    this.modalService.open(targetModal, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
  }

  deletePOIType(id) {
    this.poiService.Delete_poitype(id)
      .subscribe((resp: any) => {
        this.modalService.dismissAll();
        this.ngOnInit();
      })
  }
}
