import { Component, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
declare var google: any;
import {
  GoogleMapsAPIWrapper,
  AgmMap,
  MapsAPILoader,
  LatLngBounds,
  LatLngBoundsLiteral
} from "@agm/core";
import { PoiService } from '../services/poi.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-poi-map',
  templateUrl: './poi-map.component.html',
  styleUrls: ['./poi-map.component.scss']
})
export class PoiMapComponent {
  @ViewChild(AgmMap) agmMap: AgmMap;

  constructor(
    private appService: AppService,
    private poiService: PoiService) {
    this.appService.pageTitle = 'Point Of Interest';
  }

  zoom = 3;
  url = environment.baseUrl;
  poi = [];
  poiTypes = [];


  ngOnInit() {
    this.poiService.GetAll_Poi()
    .subscribe((resp: any) => {
      console.log(resp)
      this.poi = resp;
    })

  this.poiService.GetAll_PoiTypes()
    .subscribe((resp: any) => {
      this.poiTypes = resp;
    })
  }

}
