import { InvitecodeComponent } from './invitecode/invitecode.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import { LoginComponent } from './login/login.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { AuthGuard } from './guard/auth.guard';
import { forwardGuard } from './guard/forward.guard';
import { PoiTypesComponent } from './poi-types/poi-types.component';
import { PoiComponent } from './poi/poi.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierTypesComponent } from './supplier-types/supplier-types.component';
import { Layout2Component } from './layout/layout-2/layout-2.component';
import { CreateSupplierComponent } from './create-supplier/create-supplier.component';

// *******************************************************************************
// Routes

const routes: Routes = [

  {
    path: '', component: LoginComponent, canActivate: [AuthGuard],
  },

  {
    path: 'home', component: Layout2Component, pathMatch: 'full', children: [
      { path: '', component: HomeComponent, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'users', component: Layout2Component, children: [
      { path: '', component: Page2Component, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'poi', component: Layout2Component, children: [
      { path: '', component: PoiComponent, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'poi-types', component: Layout2Component, children: [
      { path: '', component: PoiTypesComponent, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'suppliers', component: Layout2Component, children: [
      { path: '', component: SuppliersComponent, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'supplier-types', component: Layout2Component, children: [
      { path: '', component: SupplierTypesComponent, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'create-supplier/:id', component: Layout2Component, children: [
      { path: '', component: CreateSupplierComponent, canActivate: [forwardGuard] },
    ]
  },

  {
    path: 'invitecode', component: Layout2Component, children: [
      { path: '', component: InvitecodeComponent, canActivate: [forwardGuard] },
    ]
  },

];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
