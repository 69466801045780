import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { SuppliersService } from '../services/suppliers.service';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-supplier',
  templateUrl: './create-supplier.component.html',
  styleUrls: ['./create-supplier.component.scss']
})
export class CreateSupplierComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  constructor(
    private supplierService: SuppliersService,
    private location: Location,
    private activeRoute: ActivatedRoute,
  ) { }

  updateOrCreate = '';
  supplierTypes = [];
  updateId = 0;

  createData = {
    companyName: '',
    latitude: '',
    longitude: '',
    supplierTypeId: '',
    address: '',
    phone: '',
    website: '',
    email: '',
    about: '',
  }

  alert = {
    text: '',
    color: '',
  }

  ngOnInit() {
    this.updateOrCreate = '';
    this.getAllSupplierTypes();

    this.activeRoute
      .params
      .subscribe((resp: any) => {
        console.log(resp)
        if (resp.id !== 'null') {
          this.updateOrCreate = 'Update';
          this.updateId = parseInt(resp.id);
          this.getSupplierById(parseInt(resp.id))
        } else {
          this.updateOrCreate = 'Create';
        }

      });

    console.log(this.updateOrCreate)
  }


  getSupplierById(id) {
    this.supplierService
      .Get_Supplier(id)
      .subscribe((resp: any) => {
        console.log(resp);
        this.createData = resp.supplier;
      })
  }

  getAllSupplierTypes() {
    this.supplierService.GetAll_SupplierTypes()
      .subscribe((resp: any) => {
        this.supplierTypes = resp;
      })
  }

  handleAddressChange(ev) {
    console.log(ev.geometry.location.lat(), ev.geometry.location.lng());
    this.createData.address = ev.formatted_address;
    this.createData.latitude = JSON.stringify(ev.geometry.location.lat());
    this.createData.longitude = JSON.stringify(ev.geometry.location.lng());
  }

  back(): void {
    this.location.back()
  }


  createOrUpdateClick() {
    console.log(this.createData);
    this.createData.supplierTypeId = JSON.parse(this.createData.supplierTypeId);

    if (this.updateOrCreate == 'Create') {
      this.createSupplier();
    } else {
      this.updateSupplier();
    }
  }

  createSupplier() {
    this.supplierService.Create_Supplier(this.createData)
      .subscribe((resp: any) => {
        console.log(resp);
        if (resp) {
          this.alert.color = 'primary';
          this.alert.text = 'Supplier Created Successfully!'

          setTimeout(() => {
            this.location.back();
          }, 1000);
        }

      })
  }

  updateSupplier() {
    this.supplierService
      .Update_Supplier(this.updateId, this.createData)
      .subscribe((resp: any) => {
        console.log(resp)
        if (resp) {
          this.alert.color = 'success';
          this.alert.text = 'Supplier Updated Successfully!'
          setTimeout(() => {
            this.location.back();
          }, 1000);
        }


      })
  }
}
