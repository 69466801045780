import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoiService {

  ApiUrl = environment.baseUrl

  constructor(
    public http: HttpClient
  ) { }

  GetAll_Poi() {
    return this.http.get(this.ApiUrl + 'poi/getall')
  }

  UpdateIsEnabled(id, data) {
    return this.http.post(this.ApiUrl + 'poi/update-is-enable/' + id, data)
  }

  Update_Poi(id, data) {
    return this.http.post(this.ApiUrl + 'poi/update-by-admin/' + id, data)
  }

  // ================= POI TYPES =================

  GetAll_PoiTypes() {
    return this.http.get(this.ApiUrl + 'poi-type/getall')
  }

  Create_PoiTypes(data) {
    return this.http.post(this.ApiUrl + 'poi-type/create', data)
  }

  Update_PoiTypes(id, data) {
    return this.http.post(this.ApiUrl + 'poi-type/update/' + id, data)
  }
  Delete_poitype(id) {
    return this.http.post(this.ApiUrl + 'poi-type/delete/' + id, {})
  }
  uploadImage(file) {
    const fd = new FormData();
    fd.append("image", file);
    return this.http.post(this.ApiUrl + "image/saveimage", fd);
  }
}
