import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-invitecode',
  templateUrl: './invitecode.component.html',
  styleUrls: ['./invitecode.component.scss']
})
export class InvitecodeComponent implements OnInit {

  constructor(
    private appService: AppService,
    private userService: UserService,
  ) {
    this.appService.pageTitle = 'Users';
  }

  users = [];
  url = environment.baseUrl;
  loading = false;

  config: any;
  collection = { count: 0, data: [] };


  ngOnInit() {
    this.loading = true;
    this.userService.GetAllUsers()
      .subscribe((resp: any) => {
        this.users = resp;
        this.loading = false;
      });

    this.config = {
      itemsPerPage: 50,
      currentPage: 1,
      totalItems: this.collection.count
    };
    this.collection.count = this.users.length

  }


  pageChanged(event) {
    this.config.currentPage = event;
  }
  showToast = "";
  onChangeSelect(userId, event) {
    let data = {
      inviteStatus: event.target.value
    }
    this.userService.updateInviteStatus(userId, data).subscribe(resp => {
      this.showToast = "Invite Status Updated";
      setTimeout(() => {
        this.showToast = "";
      }, 2100);
    })
  }

  expireCode(userId, index) {
    this.userService.updateInviteCode(userId, {}).subscribe((resp: any) => {
      this.users[index].inviteCode = resp.inviteCode;
      this.showToast = "Invite Code Updated";
      setTimeout(() => {
        this.showToast = "";
      }, 2100);
    })
  }

}
