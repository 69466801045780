import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss',
    '../../vendor/styles/pages/authentication.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private r: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
  }

  alertText = '';
  alert = false;

  credentials = {
    email: '',
    password: '',
  };

  loginClick() {
    this.alert = false;

    if ((this.credentials.email && this.credentials.password) !== '') {


      this.userService.Login(this.credentials)
        .subscribe((resp: any) => {
          if (resp.user.isAdmin) {
            localStorage.setItem('piper_admin', JSON.stringify(resp));
            this.r.navigate(['/home']);
          } else {
            this.alertText = "Only admin can login to Admin Panel"
            this.alert = true;
            setTimeout(() => {
              this.alert = false;
              this.alertText = '';
            }, 3000);
          }
        }, err => {
          this.alertText = "Invalid Email or Password!"
          this.alert = true;
          setTimeout(() => {
            this.alert = false;
            this.alertText = '';
          }, 3000);
        })



    } else {

      this.alertText = "Please Fill All the Fields!"
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
        this.alertText = '';
      }, 3000);

    }

  }
}
