import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class InterceptorTokenService {

  constructor(private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (localStorage.getItem('piper_admin')) {
      const tr = req.clone({
        setHeaders: {
          'access-token': JSON.parse(localStorage.getItem('piper_admin')).token
        }
      });
      return next.handle(tr).do(event => { }, err => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          localStorage.clear();
          this.router.navigate(['/']);
        }
      });
    } else {
      const tr = req.clone({
        setHeaders: {
          'access-token': ''
        }
      });
      return next.handle(tr);
    }
  }
}
