import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuppliersService } from '../services/suppliers.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private supplierService: SuppliersService,
  ) { }

  config: any;
  collection = { count: 0, data: [] };

  suppliers = [];

  ngOnInit() {
    this.supplierService.GetAll_Supplier()
      .subscribe((resp: any) => {
        this.suppliers = resp;
        console.log(resp)
      })

    this.config = {
      itemsPerPage: 50,
      currentPage: 1,
      totalItems: this.collection.count
    };
    this.collection.count = this.suppliers.length;
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  deleteData = {
    id: 0,
    companyName: '',
  }

  deleteClick(targetModal, item) {
    this.deleteData.companyName = item.companyName;
    this.deleteData.id = item.id;

    this.modalService.open(targetModal, {
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
  }

  deleteSupplierType(id) {
    this.supplierService.Delete_Supplier(id)
      .subscribe((resp: any) => {
        console.log(resp);
        this.modalService.dismissAll();
        this.ngOnInit();
      })
  }
}
