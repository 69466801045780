import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments/environment';
import { PoiService } from '../services/poi.service';

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss']
})
export class PoiComponent implements OnInit {

  constructor(
    private poiService: PoiService,
    private modalService: NgbModal,
  ) { }

  url = environment.baseUrl;

  config: any;
  collection = { count: 0, data: [] };

  poi = [];
  poiTypes = [];

  ngOnInit() {
    this.poiService.GetAll_Poi()
      .subscribe((resp: any) => {
        this.poi = resp;
      })

    this.poiService.GetAll_PoiTypes()
      .subscribe((resp: any) => {
        this.poiTypes = resp;
      })

    this.config = {
      itemsPerPage: 50,
      currentPage: 1,
      totalItems: this.collection.count
    };
    this.collection.count = this.poi.length;
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  updateIsEnabled(event, id) {
    let data = {
      isEnabled: event
    }
    this.poiService.UpdateIsEnabled(id, data)
      .subscribe((resp: any) => {
      })

  }

  editId;
  editIndex = 0;
  editData = {
    comment: "",
    poiTypeId: 2,
    poi_type: { id: 0, type: '', color: '' },
    title: "",
    imageUrl: ""
  }

  openEditModal(targetModal, item, index) {
    this.editIndex = index;
    if (item) {
      this.editId = item.id;
      this.editData.comment = item.comment;
      this.editData.title = item.title;
      this.editData.poiTypeId = item.poiTypeId;
      this.editData.imageUrl = item.imageUrl;
      this.editData.poi_type.type = item.poi_type.type;
    }
    this.modalService.open(targetModal, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
    });
  }

  onChange(event) {
    this.poiTypes.forEach(element => {
      if (element.id == event.target.value) {
        this.editData.poi_type.type = element.type;
        this.editData.poi_type.color = element.color;
      }
    });
  }

  editClick() {
    let data = {
      comment: this.editData.comment,
      title: this.editData.title,
      poiTypeId: this.editData.poiTypeId,
      imageUrl: this.editData.imageUrl
    }
    this.poi[this.editIndex].comment = data.comment;
    this.poi[this.editIndex].title = data.title;
    this.poi[this.editIndex].poiTypeId = data.poiTypeId;
    this.poi[this.editIndex].poi_type.type = this.editData.poi_type.type;
    this.poi[this.editIndex].poi_type.color = this.editData.poi_type.color;
    this.poi[this.editIndex].imageUrl = this.editData.imageUrl;

    this.poiService.Update_Poi(this.editId, data)
      .subscribe((resp: any) => {
        this.modalService.dismissAll()
      })
  }
  pickImage() {
    document.getElementById('filePicker').click();
  }
  showLoading = false;
  gotFileFromInput(event) {
    this.showLoading = true;
    this.poiService.uploadImage(event.target.files[0]).subscribe((resp: any) => {
      this.editData.imageUrl = resp;
      this.showLoading = false;
    }, err => {
      this.showLoading = false;
    })
  }
}
