import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  ApiUrl = environment.baseUrl

  constructor(
    public http: HttpClient
  ) { }

  GetAll_Supplier() {
    return this.http.get(this.ApiUrl + 'supplier/getall')
  }

  Create_Supplier(data) {
    return this.http.post(this.ApiUrl + 'supplier/create', data)
  }

  Delete_Supplier(id) {
    return this.http.post(this.ApiUrl + 'supplier/delete/' + id, {})
  }

  Update_Supplier(id, data) {
    return this.http.post(this.ApiUrl + 'supplier/update/' + id, data)
  }

  Get_Supplier(id) {
    return this.http.get(this.ApiUrl + 'supplier/get/' + id)
  }

  /* Supplier Types */

  GetAll_SupplierTypes() {
    return this.http.get(this.ApiUrl + 'supplier-type/getall')
  }

  Create_SupplierTypes(data) {
    return this.http.post(this.ApiUrl + 'supplier-type/create', data)
  }

  Update_SupplierTypes(id, data) {
    return this.http.post(this.ApiUrl + 'supplier-type/update/' + id, data)
  }
  Delete_Suppliertype(id) {
    return this.http.post(this.ApiUrl + 'supplier-type/delete/' + id, {})
  }
}
